import { Atom } from ":mods";
import { createExhibitionIdeaInputs } from "../../form";
import { FormPageProps } from "../../model";
import { STUDENT_FORM_STEPS } from "../../const";
import { Show, createMemo } from "solid-js";

export function ExhibitionIdeaPage(props: FormPageProps) {
  const FormInputs = createExhibitionIdeaInputs();

  const char_limit = 4000;
  const $exhibition_about_chars = createMemo(() => {
    const user_chars: string = FormInputs.exhibition_about.control.value;
    return `${!user_chars ? 0 : user_chars.length}/${char_limit}`;
  });
  const $exhibition_interest_chars = createMemo(() => {
    const user_chars: string = FormInputs.exhibition_interest.control.value;
    return `${!user_chars ? 0 : user_chars.length}/${char_limit}`;
  });
  const $exhibition_experience_chars = createMemo(() => {
    const user_chars: string = FormInputs.exhibition_experience.control.value;
    return `${!user_chars ? 0 : user_chars.length}/${char_limit}`;
  });

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    if (!FormInputs.Actions.canSubmit()) return;
    const values = FormInputs.Actions.getValuesLowercase({ ExhibitionPdf: "snake" });
    return props.onFormSubmit(values);
  };
  return (
    <section class={`flex flex-col justify-center items-center bg#paper w-screen text-16px ${props.class ?? ""}`}>
      <Atom.CircleProgress.CircleProgressbar steps={STUDENT_FORM_STEPS} current_step={4} />
      <h1 class="text-28px font-medium my-40px w-630px ">Tell us about your exhibition idea</h1>

      <form class="flex flex-col items-left gap-20px text-16px pt-40px w-630px" enctype="multipart/form-data">
        <div class="flex flex-col gap-10px">
          <FormInputs.exhibition_title.Label title="Please write a title for the exhibition idea you want to develop">
            <FormInputs.exhibition_title.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.exhibition_title.Label>
          <FormInputs.exhibition_title.Input
            autocomplete="exhibition_title"
            type="text"
            class=" bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
            placeholder=""
          />
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.exhibition_about.Label
            class="w-521px"
            title="Please tell us more about your idea. What is the exhibition about? who would be interested in attending and how would you tell the story in a museum space?"
          >
            <FormInputs.exhibition_about.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.exhibition_about.Label>
          <div class="border-2 border#p border-solid w-630px">
            <div class="relative w-full">
              <FormInputs.exhibition_about.TextArea
                autocomplete="exhibition_about"
                class="textarea-resize px-2.5"
                placeholder=""
                maxlength={char_limit}
              />
              <div class="absolute right-15px bottom-10px text-gray-300 text-19px">{$exhibition_about_chars()}</div>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.exhibition_interest.Label
            class="w-521px"
            title="Please tell us more about your idea. Who would be interested in visiting the exhibition and why? and how would you tell the story in a museum space?"
          >
            <FormInputs.exhibition_interest.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.exhibition_interest.Label>
          <div class="border-2 border#p border-solid w-630px">
            <div class="relative w-full">
              <FormInputs.exhibition_interest.TextArea
                autocomplete="exhibition_interest"
                class="textarea-resize px-2.5"
                placeholder=""
                maxlength={char_limit}
              />
              <div class="absolute right-15px bottom-10px text-gray-300 text-19px">{$exhibition_interest_chars()}</div>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.exhibition_experience.Label
            class="w-521px"
            title="Please tell us more about your idea? How would you allow visitors to experience the story, within a museum space?"
          >
            <FormInputs.exhibition_experience.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.exhibition_experience.Label>
          <div class="border-2 border#p border-solid w-630px">
            <div class="relative w-full">
              <FormInputs.exhibition_experience.TextArea
                autocomplete="exhibition_experience"
                class="textarea-resize px-2.5"
                placeholder=""
                maxlength={char_limit}
              />
              <div class="absolute right-15px bottom-10px text-gray-300 text-19px">
                {$exhibition_experience_chars()}
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.ExhibitionPdf.Label title="If you wish to upload a PDF to help better communicate your idea, please upload it here (NOT REQUIRED)">
            <FormInputs.ExhibitionPdf.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.ExhibitionPdf.Label>

          <FormInputs.ExhibitionPdf.InputFile
            accept=".pdf"
            classes={{
              container:
                "flex flex-col justify-center items-center bg-inherit border-2 border#p border-dashed text-14px px-2.5 py-50px w-630px",
            }}
            elements={{
              input({ onInputClicked, viewer_data, drag }) {
                return (
                  <div
                    onclick={onInputClicked}
                    class="flex flex-col w-full h-full justify-center items-center gap-5px cursor-pointer"
                  >
                    <i class="icon-local:upload-icon w-29.5px h-19.6px" />
                    <p class="ms-2px cursor-pointer">
                      <Show when={!drag} fallback={"Drop Files"}>
                        Drag files here or <span class="underline">choose a file</span>
                      </Show>
                    </p>
                    <Show when={viewer_data.file}>{viewer_data.file.name}</Show>
                  </div>
                );
              },
            }}
          />
        </div>

        <div class="flex justify-end gap-1rem">
          <Atom.Form.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 w-35px h-23px mt-20px mb-80px px-30px py-20px rounded-sm"
            controls={[]}
            onclick={props.onBack}
            statusValid={"Back"}
          />
          <FormInputs.Actions.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 w-35px h-23px mt-20px mb-80px px-30px py-20px rounded-sm"
            onclick={onSubmit}
            // statusInvalid={(control) => !control?"Fill Up Form" : "Fill Up "+ String(control.id)}
            statusValid={"Next"}
            statusInvalid={"Next"}
          />
        </div>
      </form>
    </section>
  );
}
